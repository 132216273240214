import React from "react";
import { Link, navigate } from "gatsby";
import Img from "gatsby-image";

import { TriangleTopRight } from "./triangles";

export default props => (
  <div>
    <div
      className="uk-card uk-card-default uk-transition-toggle uk-box-shadow-hover-large pointer"
      onClick={() => navigate(props.LinkUrl)}
      onKeyDown={() => navigate(props.LinkUrl)}
      role="link"
      tabIndex="0"
    >
      <div className="uk-card-media-top uk-cover-container">
        <div className="uk-transition-scale-up uk-transition-opaque">
          {/* <div className="uk-card-badge uk-label">{props.Label}</div> */}
          <Img fluid={props.ImageFluid} />
        </div>
      </div>
      {props.Label && <TriangleTopRight Label={props.Label} />}
      <div className="uk-card-body">
        {/* {loadTitle(props.Title, props.Subtitle)} */}

        <h5 className="uk-text-truncate">
          {props.Title}
          {props.Subtitle && <small>{" - " + props.Subtitle}</small>}
        </h5>

        <p>{props.Intro}</p>
        <div className="uk-padding-small uk-position-small uk-position-bottom-right">
          <Link
            to={props.LinkUrl}
            className="uk-button uk-button-link"
          >
            <span
              className="uk-margin-small-right"
              data-uk-icon="chevron-right"
            />
            {props.LinkText}
          </Link>
        </div>
      </div>
    </div>
  </div>
);
