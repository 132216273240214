import React from "react";
import { graphql } from "gatsby";
// import { Link } from "gatsby";

import Head from "../components/head";
import Layout from "../components/layout";
import Section from "../components/section";
import Grid from "../components/grid";
import Card from "../components/card";

const PageTitle = "Projets et Réalisations";

const filterServices = ({ data }) => {
  var servicesArray = [];
  data.allMarkdownRemark.edges.forEach(({ node }) => {
    servicesArray = servicesArray.concat(node.frontmatter.services);
    servicesArray = Array.from(new Set(servicesArray));
    // servicesArray = servicesArray.sort();
  });
  return servicesArray;
};

const printServices = ({ data }) => {
  var servicesArray = filterServices({ data });
  return servicesArray.map((service, index) => {
    return (
      <li
        key={index}
        data-uk-filter-control={"[data-services*='" + service + "']"}
      >
        <a href="/#">{service}</a>
      </li>
    );
  });
};

export default ({ data }) => (
  <div>
    <Head PageTitle={PageTitle} PageSlug="/projects/" />

    <Layout>
      <Section Style="secondary uk-light uk-text-center">
        <h3>{PageTitle}</h3>
        <div>
          Voici quelques projets réalisés pour vous donner une idée... Tout est
          possible !
        </div>
      </Section>

      <Section Style="muted">
        <div data-uk-filter="#js-filter">
          <ul className="uk-subnav uk-subnav-pill uk-flex uk-flex-center">
            <li data-uk-filter-control="" className="uk-active">
              <a href="/#">tous</a>
            </li>
            {printServices({ data })}
          </ul>

          <Grid Id="js-filter" Col="3">
            {data.allMarkdownRemark.edges.map(({ node }) => {
              const nodeServices = node.frontmatter.services.join(" ");
              // const services =
              //   node.frontmatter.services &&
              //   node.frontmatter.services.map((service, index) => {
              //     console.log(index);
              //     return (
              //       <Link key={index} to={"/services/" + service}>
              //         <span className="uk-badge uk-margin-small-right uk-margin-small-bottom uk-padding-small">
              //           {service}
              //         </span>
              //       </Link>
              //     );
              //   });
              // const skills =
              //   node.frontmatter.skills &&
              //   node.frontmatter.skills.map((skill, index) => {
              //     return (
              //       <Link key={index} to={"/skills/" + skill}>
              //         <span className="uk-badge uk-margin-small-right uk-margin-small-bottom uk-padding-small">
              //           {skill}
              //         </span>
              //       </Link>
              //     );
              //   });
              // const nodeIntro = () => {
              //   return [
              //     <p>{node.excerpt}</p>,
              //     <p>SERVICES : <br/>{services}</p>,
              //     <p>COMPETENCES : <br/>{skills}</p>
              //   ];
              // };
              return (
                <div key={node.id} data-services={nodeServices}>
                  <Card
                    ImageFluid={
                      node.frontmatter.cover_image.childImageSharp.fluid
                    }
                    Label={node.frontmatter.date}
                    Title={node.frontmatter.title}
                    Subtitle={node.frontmatter.subtitle}
                    Intro={node.excerpt}
                    // Intro={nodeIntro()}
                    // LinkUrl={node.fields.slug}
                    LinkUrl={"/projects/" + node.frontmatter.titleId}
                    LinkText="Découvrir"
                  />
                </div>
              );
            })}
          </Grid>
        </div>
      </Section>
    </Layout>
  </div>
);

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categoryId: { eq: "projects" } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            titleId
            subtitle
            date(formatString: "YYYY")
            cover_image {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 225) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            services
            skills
          }
          fields {
            slug
          }
          excerpt(pruneLength: 90)
        }
      }
    }
  }
`;
